const links = {
    "REWARDSPAGELINK": {
        text: "Rewards",
        type: "nav",
        link: "/rewards"
    },
    "ENROLLHERE": {
        text: "here",
        type: "nav",
        link: "/rewards"
    },
    "SIGNUP": {
        text: "sign up",
        type: "nav",
        link: "/rewards"
    },
    "LIBERTYREWARDS": {
        text: "Liberty Rewards",
        type: "nav",
        link: "/rewards"
    },
    "ONLINEMENU": {
        text: "online menu",
        type: "nav",
        link: "/shop"
    },
    "MENU": {
        text: "menu",
        type: "nav",
        link: "/shop/?dtche%5Bpath%5D=info"
    },
    "BECOMEAPATIENTHERE": {
        text: "here",
        type: "nav",
        link: "/become-a-patient"
    },
    "BECOMEAPATIENTLINK": {
        text: "become a patient",
        type: "nav",
        link: "/become-a-patient"
    },
    "BECOMEMARIJUANAPATIENT": {
        text: "how to become a medical marijuana patient",
        type: "nav",
        link: "/become-a-patient"
    },
    "BECOMEAPATIENTONE": {
        text: "learn how",
        type: "nav",
        link: "/become-a-patient"
    },
    "REWARDSPAGEEXT": {
        text: "rewards page",
        type: "link",
        link: "https://join.mywallet.deals/?id=fc074ce5-6846-4689-9e71-0afc84ee4208#/login"
    },
    "REWARDSPAGE": {
        text: "Rewards page",
        type: "link",
        link: "/rewards"
    },
    "CAREERLINK": {
        text: "here",
        type: "link",
        link: "https://careers.jobscore.com/careers/holisticindustries"
    },
    "BR": {
        text: "break",
        type: "break",
        link: "break"
    },
    "ONLINE": {
        text: "online",
        type: "link",
        link: "https://enrollnow.vip/join/2317"
    },
    "HOLDANORDER": {
        text: "hold an order",
        type: "navdropdownclick",
        link: "#PA24"
    },
    "TAXREGULATIONS": {
        text: "Tax Regulations",
        type: "link",
        link: "https://www.cdtfa.ca.gov/industry/cannabis.htm"
    },
    "STATEREGULATIONS": {
        text: "state regulations",
        type: "link",
        link: "https://www.bcc.ca.gov/"
    },
    "CLICKHERE": {
        text: "click here",
        type: "link",
        link: "https://www.cdph.ca.gov/Programs/CHSI/Pages/MMICP.aspx"
    },
    "MMICCARDHERE": {
        text: "here",
        type: "link",
        link: "https://www.cdph.ca.gov/Programs/CHSI/Pages/MMICP.aspx"
    },
    "SIGNINGUP": {
        text: "signing up",
        type: "nav",
        link: "#sitefooter"
    },
    "CAREERRELATIVELINK" : {
        text: "https://careers.jobscore.com/careers/holisticindustries",
        type: "link",
        link: "https://careers.jobscore.com/careers/holisticindustries",
    },
    "BBCCAGOVLInk" : {
        text: "bbc.ca.gov",
        type: "link",
        link: "https://www.bcc.ca.gov",
    },
    "CDTFACAGOVLINK" : {
        text: "cdtfa.ca.gov",
        type: "link",
        link: "https://www.cdtfa.ca.gov/industry/cannabis.htm",
    },
    "LIBERTYREWARDLINK" : {
        text: "LibertyCannabis.com/pages/rewards",
        type: "nav",
        link: "/rewards",
    },

};

module.exports = links;
