const rewardsFAQs = {
    faq_data: [{
            id: 1,
            name: "I can’t log in to my account.",
            description: "<p>There may be several reasons you can’t log in to Liberty Wallet. <b>Have you created an account directly within the app?</b> Liberty Wallet is completely separate from other accounts you may have created on the website or in store. If they have not created an account within the app, you will not be able to log in.</p><p>If you have created a separate Liberty Wallet account and you can’t log in, <strong>double check the email you used</strong>. You should have received an activation email to confirm your email address—you need to do this before you log in for the first time. Check your spam and junk folders to make sure it didn’t go there accidentally.</p>",
            abbr: "LIBERTYWALLETANDREWARDS",
            tag: "liberty-wallet-rewards",
            location: "General",
        },
        {
            id: 2,
            name: "My SpringBig credits are not showing up in app.",
            description: "The SpringBig program was retired in August 2021. It is no longer being supported.",
            abbr: "LIBERTYWALLETANDREWARDS",
            tag: "liberty-wallet-rewards",
            location: "General",
        },
        {
            id: 3,
            name: "I am unable to connect to my bank.",
            description: "If you are receiving a generic error message or the app is very slow, we recommend you try again with a better signal or over WiFi. Accounts cannot be connected without a network connection. Please ask a Liberty staff member for the Guest Wifi information if needed.",
            abbr: "LIBERTYWALLETANDREWARDS",
            tag: "liberty-wallet-rewards",
            location: "General",
        },
        {
            id: 4,
            name: "I am receiving the error message: “We are unable to connect that bank at this time.”",
            description: "If you belong to a small bank or credit union, it is possible that it is not supported by Yodlee, our financial data platform. If this is the case, Yodlee has to deploy a custom solution to link accounts. Due to the variety and volume of banks and credit unions nationwide, this could take several days; therefore, it is unlikely you will be able to make a transfer that day. A Liberty staff member will take the email address you used to create your Liberty Wallet account and notify you when the problem is fixed.",
            abbr: "LIBERTYWALLETANDREWARDS",
            tag: "liberty-wallet-rewards",
            location: "General",
        },
        {
            id: 5,
            name: "I keep getting the error message: “There was a problem with your account, please contact Liberty.”",
            description: "You are receiving that message because there has been a failed transfer and your account has been locked, most likely because you have a negative account balance. This can occur if you transferred funds into your Liberty Wallet but there were insufficient funds in your bank account. Once you transfer enough funds into your Liberty Wallet account to cover the deficit, normal functioning should resume. ",
            abbr: "LIBERTYWALLETANDREWARDS",
            tag: "liberty-wallet-rewards",
            location: "General",
        },
        {
            id: 6,
            name: "My order history is blank.",
            description: "If your order history is blank, the email you used for your Wallet account does not match the email address on file with our in-store point-of-sale system. Please ask a Wellness Guide or Liberty staff member to update your email address with the one you used for Liberty Wallet. Within 24 hours, your order history should populate.",
            abbr: "LIBERTYWALLETANDREWARDS",
            tag: "liberty-wallet-rewards",
            location: "General",
        },
        {
            id: 7,
            name: "I did not receive credits on my recent purchase.",
            description: "<p>Credits can take up to 24 hours to appear in your wallet. </p><p>If it has been more than 24 hours, check that the email address used for your Wallet account matches the email address in our in-store point-of-sale system. If it is different, your Wellness Guide or Liberty staff member can update your email address with the one you used for Liberty Wallet. Within 24 hours, your credits should populate.</p><p>If it has been more than 24 hours and the email address is correct, your account may be locked due to a rejected transfer. This would cause the balance in your wallet to be negative. Rewards can not be applied to a negative balance. Once you have transferred funds and the account balance is above $0, you can start receiving credits again.</p>",
            abbr: "LIBERTYWALLETANDREWARDS",
            tag: "liberty-wallet-rewards",
            location: "General",
        },
        {
            id: 8,
            name: "There seems to be something wrong with the app on my phone. I’m not seeing all the features.",
            description: "<p>If something is missing in the app or if the user interface is unusable, uninstall and then re-download the latest version of Liberty Wallet. This will resolve most issues. </p><p>If the issue persists, a Liberty staff member will file a support ticket on your behalf to further investigate the issue. They will need the following information:</p><ul><li>The email address used to create the account</li><li>Your phone platform (iPhone, Android, etc)</li><li>Your phone type (iPhone 12, Samsung Galaxy S20, etc)</li><li>Screenshots of the issue</li></ul>",
            abbr: "LIBERTYWALLETANDREWARDS",
            tag: "liberty-wallet-rewards",
            location: "General",
        },
    ],
}

module.exports = rewardsFAQs;
